import React, {useEffect, useState} from 'react';
import {ProductType} from '@wix/wixstores-client-core';
import {TextButton, Tooltip} from 'wix-ui-tpa/cssVars';
import {useControllerProps} from '../../../../../domain/controllers/ControllerContext';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {TagIcon} from '../Icons/Tag';
import {useSettingsWithDefaults} from '../../../../../hooks/useSettingsWithDefaults';
import settingsParams from '../../../settingsParams';
import {CouponInput} from './CouponInput/CouponInput';

export enum CouponDataHook {
  CouponRoot = 'Coupon.Root',
  EnterACouponButton = 'Coupon.EnterACouponButton',
  EnterACouponButtonDisabledTooltip = 'Coupon.EnterACodeButtonDisabledTooltip',
  CouponInputRoot = 'Coupon.CouponInputRoot',
}

export const Coupon = () => {
  const {t} = useTranslation();
  const {cartModel, estimatedTotals, isGiftCardMixedCartEnabled, sendToggleCouponBi} = useControllerProps().cartStore;
  const appliedCoupon = estimatedTotals?.appliedCoupon || cartModel.appliedCoupon;
  const isCouponApplied = !!appliedCoupon;
  const shouldDisableCoupon = isGiftCardMixedCartEnabled && cartModel.lineItemsTypes.includes(ProductType.GIFT_CARD);
  const {getSettingsWithDefaults} = useSettingsWithDefaults();
  const [isCollapsed, setIsCollapsed] = useState(!isCouponApplied);

  useEffect(() => {
    if (!isCouponApplied) {
      return;
    }
    setIsCollapsed(!isCouponApplied);
  }, [isCouponApplied]);

  const onTextButtonClicked = () => {
    isCollapsed && sendToggleCouponBi();
    setIsCollapsed(!isCollapsed);
  };

  const textButton = (
    <TextButton
      data-hook={CouponDataHook.EnterACouponButton}
      prefixIcon={<TagIcon />}
      aria-expanded={!isCollapsed}
      onClick={onTextButtonClicked}
      disabled={shouldDisableCoupon}>
      {getSettingsWithDefaults(settingsParams.CART_ADD_PROMO_CODE_LINK)}
    </TextButton>
  );

  return (
    <div data-hook={CouponDataHook.CouponRoot}>
      {shouldDisableCoupon ? (
        <Tooltip
          data-hook={CouponDataHook.EnterACouponButtonDisabledTooltip}
          content={t('cart.errors.coupons.ERROR_CANT_PURCHASE_GC_MESSAGE')}
          minWidth={220}>
          {textButton}
        </Tooltip>
      ) : (
        textButton
      )}
      {!isCollapsed && (
        <div>
          <CouponInput dataHook={CouponDataHook.CouponInputRoot} />
        </div>
      )}
    </div>
  );
};
