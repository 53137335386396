import {TextField} from 'wix-ui-tpa/cssVars';
import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';

export enum CouponInputDataHook {
  TextInput = 'Coupon.TextInput',
}

export interface CouponInputProps {
  dataHook?: string;
}

export const CouponInput = ({dataHook}: CouponInputProps) => {
  const {t} = useTranslation();

  return (
    <div data-hook={dataHook}>
      <TextField data-hook={CouponInputDataHook.TextInput} placeholder={t('cart.coupon_input_placeholder')} />
    </div>
  );
};
