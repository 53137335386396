import React from 'react';
import {useTranslation} from '@wix/yoshi-flow-editor';
import {OrderSummaryDataHook} from '../../OrderSummary';
import {useControllerProps} from '../../../../../../../domain/controllers/ControllerContext';
import s from './ShippingDestination.scss';

type ShippingDestinationProps = {
  dataHook: string;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ShippingDestination = ({dataHook}: ShippingDestinationProps) => {
  const {t} = useTranslation();
  const {hasShippingDestination, handleChangeRegion, shouldShowShipping, shouldShowTax, shouldHideCountryPicker} =
    useControllerProps().orderStore;

  const {showShippingDestinationChangeRegionExplain} = useControllerProps().cartStore;

  const {cartModel} = useControllerProps().cartStore;

  const getChooseRegionTranslationKey = (): string => {
    if (shouldShowShipping && shouldShowTax) {
      return 'cart.shipping_destination.shipping_and_tax.choose_region';
    }
    if (shouldShowShipping && !shouldShowTax) {
      return 'cart.shipping_destination.choose_region';
    }
    return 'cart.shipping_destination.tax.choose_region';
  };

  const getShippingRegion = (): string => {
    const {contactInfo} = cartModel;

    let shippingLocation = t('cart.fallback_estimated_shipping_address');

    if (contactInfo.address.countryFullname) {
      shippingLocation = contactInfo.address.countryFullname;

      if (contactInfo.address.subdivisionFullname) {
        shippingLocation = t('cart.estimated_shipping_address', {
          country: contactInfo.address.countryFullname,
          subdivision: contactInfo.address.subdivisionFullname,
        });
      }
    }

    return shippingLocation;
  };

  return shouldHideCountryPicker ? null : (
    <div className={s.location} data-hook={dataHook}>
      {showShippingDestinationChangeRegionExplain && hasShippingDestination && (
        <span
          data-hook={OrderSummaryDataHook.ShippingDestinationChangeRegionExplain}
          id="change-region-description"
          style={{display: 'none'}}>
          {t('cart.sr_change_destination')}
        </span>
      )}

      <button
        data-hook={OrderSummaryDataHook.ShippingDestinationChangeRegion}
        type="button"
        onClick={() => handleChangeRegion()}
        aria-haspopup={true}
        aria-describedby="change-region-description"
        {...(!showShippingDestinationChangeRegionExplain &&
          hasShippingDestination && {'aria-label': t('cart.sr_change_destination')})}>
        {hasShippingDestination ? getShippingRegion() : t(getChooseRegionTranslationKey())}
      </button>
    </div>
  );
};
